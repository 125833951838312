import {ContactForm, FullPhoto, Hero, Layout, Navigation, TextBox, WhatWeDid,} from "components/CaseLayout";
import SEO from "components/seo";
import React from "react";
import {useCaseData} from "shared/Hooks/CasesNew/useCaseData";
import {Bloodlab} from "shared/Types/cases";
import {ContentPhoto} from "../../components/CaseLayout";

const BloodlabPage = () => {
  const data = useCaseData(Bloodlab);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo} image={data.opengraph}/>
      <Hero data={data.hero} color={data.color}/>
      <WhatWeDid data={data.what_we_did}/>
      <Navigation type={Bloodlab}/>
      <ContentPhoto data={data.content_images.logo} content={450}/>
      <TextBox data={data.text_boxes.first}/>
      <ContentPhoto data={data.content_images.first}/>
      <ContentPhoto data={data.content_images.second}/>
      <FullPhoto data={data.full_images.first}/>
      <TextBox data={data.text_boxes.second}/>
      <FullPhoto data={data.full_images.second}/>
      <FullPhoto data={data.full_images.third}/>
      <TextBox data={data.text_boxes.third} color={data.color}/>
      <FullPhoto data={data.full_images.fourth}/>
      <TextBox data={data.text_boxes.fourth} color={data.color}/>
      <FullPhoto data={data.full_images.fifth}/>
      <TextBox data={data.text_boxes.fifth}/>
      <FullPhoto data={data.full_images.sixth}/>
      <TextBox data={data.text_boxes.sixth} color={data.color}/>
      <ContentPhoto data={data.content_images.fourth} content={846} color={data.color}/>
      <FullPhoto data={data.full_images.seventh}/>
      <TextBox data={data.text_boxes.seventh}/>
      <FullPhoto data={data.full_images.eighth}/>
      <FullPhoto data={data.full_images.ninth}/>
      <TextBox data={data.text_boxes.eighth} color={data.color}/>
      <FullPhoto data={data.full_images.tenth}/>
      <TextBox data={data.text_boxes.ninth} />
      <FullPhoto data={data.full_images.eleventh}/>
      <FullPhoto data={data.full_images.twelfth}/>


      <ContactForm/>
    </Layout>
  );
};

export default BloodlabPage;
